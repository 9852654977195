@charset "utf-8";

@import "../assets/vendors/liquid-icon/liquid-icon.min.css";
@import "../assets/vendors/font-awesome/css/font-awesome.min.css";
@import "../assets/css/theme-vendors.min.css";
@import "../assets/css/theme.min.css";
@import "../assets/css/themes/seo.css";


// COLORS
// $primary: #00d1b2;
// $info: #209CEE;
// $success: #23D160;
// $warning: #FFDD57;
// $danger: #FF3860;
// $light: #F5F5F5;
// $dark: #363636;

// TEXT
// $text: #4A4A4A;
// $link: #3273DC;
// $body-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
//   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//   "Helvetica", "Arial", sans-serif;
// $title-family: false;
// $button-family: false;

// BREAKPOINTS
// $gap: 32px;
// $tablet: 769px;
// $desktop: 960px + (2 * $gap);
// $widescreen: 1152px + (2 * $gap);
// $fullhd: 1344px + (2 * $gap);
// $widescreen-enabled: true;
// $fullhd-enabled: false;

// LAYOUT
// $section-padding: 3rem 1.5rem;
// $section-padding-medium: 6rem 1.5rem;
// $section-padding-large: 9rem 1.5rem;

// SEE DOCS FOR MORE:
// https://bit.ly/30UvE5O

// IMPORT BULMA
// @import "~bulma/bulma.sass";

// IMPORT FONT AWESOME
// @import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");



#main-slider{
  background-image: url('../assets/demo/bg/bg-75.svg');
  background-position: 170% 110%;
}
.header-call-btn{
  color: var(--color-primary);
}

#our-features{
  background-image: url('../assets/demo/bg/bg-76.svg'); 
  background-position: -90% 80%;
}

#our-services{
  background-image: url('../assets/demo/bg/bg-77.svg'); 
  background-position: 600% 100%;
  padding-top: 400px;
  margin-top: -300px;
}

#testimonials{
  background-image: url('../assets/demo/bg/bg-78.svg'); 
  background-position: 12% 35%;
}

.h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6, body{
  font-family: glacial indifference,sans-serif;
}

.line-btns{
  text-transform: uppercase;
    color: var(--color-primary);
    letter-spacing: 1px;
    border-bottom: 1px solid;
    font-size: 12px;
}

.main-nav{
  li{
    a{
      letter-spacing: 1px;
      font-weight: 600;
    }
  }
}
.main-header {
  .header-module{
    p{
      font-weight: 600;
    }
  } 
}
.header-call-btn{
  a{
    color:var(--color-primary);
  }
}

.lqd-parallax-images-7 {
  .liquid-img-group-single:last-of-type{
    margin-right: -50%;
    margin-left: 0;
    margin-bottom: 0px;
    position: inherit;
  }
}

.FooterComponent__container{
  margin-top: 50px;
}

.floating-cta{
  position: fixed;
  width: 300px;
  bottom: 30px;
  left: 30px;
  z-index: 99;
  .close-btn{
    top: 10px;
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
}

.about-us{
  .lqd-parallax-images {
    .liquid-img-group-single:last-child{
      bottom: -350px;
    }
  }
}
.bg-custom{
  background: #FBF5F3;
}
.book-now{
  .phone-link{
    h3{
      margin: 30px 0px;
      font-size: 35px;
      color: #fd5460;
      border-bottom: 1px solid;
      width: max-content;
      padding: 10px 0px;
      i{
        margin-right: 20px;
      }
    }
  }
  .certificates{
    margin: 50px 0px;
  }
  .features-list{
    margin: 20px 0px;
    .single-feature{
      display: flex;
      font-size: 25px;
      padding: 10px 0px;
      i{
        font-weight: bold;
        color: #232323;
        margin-right: 20px;
      }
      h5{
        margin: 0px;
        font-size: 25px;
        font-weight: bold;
      }
    }
  }
}
.ld-pf-image {
  figure{
    background-size: contain;
  }
}
.pf-details-boxed {
  
  
  .ld-pf-details{
    width: 90%;
    .ld-pf-title{
      font-weight: bold;
    }
  }
}

#cpa-hero{
  background-image: url('../assets/demo/banners/main-banner.jpg');
  background-size: cover;
  background-position: center;
}

#cpa-numbers{
  background-image: url('../assets/demo/bg/bg-25.jpg');
  background-size: cover;
  background-position: center;
}
.liquid-row-overlay{
  background:rgba(9, 17, 35, 0.645);
}

.cpa-contact{
  background-image: url('../assets/demo/bg/bg-30.jpg');
  background-size: cover;
  background-position: center;
}

.two-btns{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .col-now-btn, .email-us-btn{
    a{
      display: flex;
      align-items: center;
      border-bottom: 1px solid;
      i{
        color: #FD7250;
        margin-right: 10px;
      }
      p{
        color: #fff;
        // font-weight: bold;
        margin-bottom: 0px;
      }
    }
  }
}

#cpa-services {
  a{
    color: #232323;
  }
}

.cpa-page{
  .main-nav{
    li{
      a{
        color: #fff;
      }
    }
  } 
  .main-header{
    .header-module {
      p{
        color: #fff;
      }
    }
  } 
  #smm-services{
    .iconbox{
      a{
        color: #232323;
      }
    }
  }
}

.my-custom-bg{
  background: rgba(0,0,0,0.5);
}
.featured-service{
  // border: 1px solid #fff;
  h4{
    color: #fff;
    font-weight: bold;
  }
  p{
    color: #fff;
  }
}
.featured-service:first-child{
  // border-left: 1px solid #fff;
}
.border-right{
  border-right: 1px solid;
}

.contact-form{
  button{
    width: 100%;
    padding: 15px;
    color: #fff;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    border-radius: 30px;
    background-image: linear-gradient(to right, #fd5264 0%, #fc6731 50%,#fd5168 100%);
    transition: all .2s ease-in-out;
    background-size: 200% 100%;
  }
  button:hover{
    background-position: right center;
  }
}

#cpa-hero{
  .contact-form {
    input{
      background: #ededed;
      color: #232323;
      font-size: 15px;
    }
    textarea{
      background: #ededed;
      color: #232323;
      font-size: 15px;
    }
  }
}

.notification.is-success{
  text-align: center;
  padding: 10px;
  color: #28A745;
  font-weight: bold;
  margin-bottom: 20px;
  border-radius: 5px;
  background: rgba(40, 167, 69, 0.1);
}
.notification.is-success{
  text-align: center;
  padding: 10px;
  color: #DC3545;
  font-weight: bold;
  margin-bottom: 20px;
  border-radius: 5px;
  background: rgba(220, 53, 69, 0.1);
}